// Firebase imports
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, onValue, update, remove } from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage"

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const storage = getStorage(app);

class FinanceDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/finance")
  }

  getFinanceCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  async uploadFile(financeRefKey, file) {
    if (!file || !financeRefKey) {
      throw new Error(
        "File and Finance Reference Key are required for uploading."
      )
    }

    // Create a storage reference in Firebase
    const fileRef = storageRef(storage, `finance/${financeRefKey}/${file.name}`)

    // Upload the file to Firebase Storage
    await uploadBytes(fileRef, file)

    // Get the download URL
    const downloadURL = await getDownloadURL(fileRef)

    // Update the finance record with the file URL
    const specificFinanceRef = ref(getDatabase(), `/finance/${financeRefKey}`)
    return update(specificFinanceRef, { fileURL: downloadURL })
  }

  async olduploadFile(invoiceID, file) {
    if (!file || !invoiceID) {
      throw new Error("File and Invoice ID are required for uploading.")
    }

    // Create a storage reference in Firebase
    const fileRef = storageRef(storage, `finance/${invoiceID}/${file.name}`)

    // Upload the file to Firebase Storage
    await uploadBytes(fileRef, file)

    // Get the download URL
    const downloadURL = await getDownloadURL(fileRef)

    // Update the finance record with the file URL
    const specificFinanceRef = ref(getDatabase(), `/finance/${invoiceID}`)
    return update(specificFinanceRef, { fileURL: downloadURL })
  }

  getAll(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      let finance = []
      if (data) {
        finance = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      callback(finance)
    })
  }

  createFinance(finance) {
    return push(this.dbRef, finance).then(ref => ref.key)
  }

  oldcreateFinance(finance) {
    return push(this.dbRef, finance)
  }

  updateFinance(key, value) {
    const specificFinanceRef = ref(getDatabase(), `/finance/${key}`)
    return update(specificFinanceRef, value)
  }

  deleteFinance(key) {
    const specificFinanceRef = ref(getDatabase(), `/finance/${key}`)
    return remove(specificFinanceRef)
  }
}

export default new FinanceDataService();