import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { getAuth, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

const UserUpdate = () => {
  const [userProfile, setUserProfile] = useState({
    displayName: '',
    photoFile: null
  });
  const auth = getAuth();
  const storage = getStorage();
  const firestore = getFirestore();

  useEffect(() => {
    if (auth.currentUser) {

      const { displayName } = auth.currentUser;
      setUserProfile({ ...userProfile, displayName });
    }
  }, [auth.currentUser]);

  const handleFileChange = (e) => {

    try 
    {
        setUserProfile({ ...userProfile, photoFile: e.target.files[0] });
    } 
    catch (error) 
    {
        console.log(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserProfile({ ...userProfile, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { displayName, photoFile } = userProfile;

    try {
      if (photoFile) {
        // Upload image to Firebase Storage
        const storageRef = ref(storage, `userphotos/${auth.currentUser.uid}`);
        await uploadBytes(storageRef, photoFile);
        const photoURL = await getDownloadURL(storageRef);

        // Update user profile in Firebase Authentication
        await updateProfile(auth.currentUser, { displayName, photoURL });

        // Save user ID and image URL in Firestore
        //const userPhotoDoc = doc(firestore, 'userphoto', auth.currentUser.uid);
        //await setDoc(userPhotoDoc, { uid: auth.currentUser.uid, photoURL });

        alert('Profile updated successfully');
      } 
      else 
      {
        // Update only the display name if no photo is uploaded
        await updateProfile(auth.currentUser, { displayName });
        alert('Display name updated successfully');
      }
    } catch (error) {
      console.error('Error updating profile: ', error);
      alert('Error updating profile');
    }
  };

  return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="displayName">Display Name</Label>
          <Input
            type="text"
            name="displayName"
            id="displayName"
            value={userProfile.displayName || ''}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="photoFile">Profile Photo</Label>
          <Input
            type="file"
            name="photoFile"
            id="photoFile"
            onChange={handleFileChange}
          />
        </FormGroup>
        <Button type="submit"  color="danger">Update Profile</Button>
      </Form>
  
  );
};

export default UserUpdate;
