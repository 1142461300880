import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import AgreementDataService from "services/AgreementDataService"
import { v4 as uuidv4 } from "uuid"
import OrganisationDataService from "services/OrganisationDataService"

const AgreementManager = () => {
  const [agreement, setAgreement] = useState([])
  const [companyName, setCompanyName] = useState("")
  const [organisations, setOrganisations] = useState([])

  const [currentOrg, setCurrentOrg] = useState({
    businessName: "",
    businessID: "",
  })

  useEffect(() => {
    if (!localStorage.getItem("authUserDetails")) {
      // console.log("No user details found...")
    } else {
      // console.log("LS " + localStorage.getItem("authUserDetails"))
      if (localStorage.getItem("authUserDetails")) {
        let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

        const company = userDetails.userCompany
        console.log("Company is: " + company)
        setCompanyName(company)

        if (userDetails.userType === "Super Admin") {
          console.log("setting as Super Admin.")
        } else {
          location.href = "/dashboard"
        }
      } else {
        console.log("No user details found in localStorage.")
      }
    }
  }, [])

  const [currentAgreement, setCurrentAgreement] = useState({
    agreementName: "",
    specialRate: "",
    billingCycle: "",
    createdBy: "",
    createdOn: "",
    workerRates: "",
    workerType: "",
    skill: "",
    hourlyRate: "",
    finalHourlyCost: "",
    status: "",
    agreementID: "",
    companyName: "",
  })

  const [selectedOrg, setSelectedOrg] = useState("")

  const handleSelectChange = e => {
    setSelectedOrg(e.target.value)
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const unsubscribe = AgreementDataService.getAll(
      data => {
        setAgreement(data)
      },
      [currentAgreement]
    )
    return () => unsubscribe && unsubscribe()
  }, [])

  const toggleModal = () => {
    console.log(isModalOpen)
    setIsModalOpen(!isModalOpen)
    if (isModalOpen) {
      resetForm()
    }
  }

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setCurrentAgreement({ ...currentAgreement, [name]: value });
  // };

  const handleInputChange = event => {
    const { name, value } = event.target

    if (name === "agreementID") {
      const fixedPrefix = currentAgreement.agreementID.substring(0, 14)

      if (value.startsWith(fixedPrefix)) {
        setCurrentAgreement({ ...currentAgreement, [name]: value })
      }
    } else {
      setCurrentAgreement({ ...currentAgreement, [name]: value })
    }
  }

  const generateAgreementID = () => {
    const year = new Date().getFullYear()
    const uuid = uuidv4()
    const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
    return `AGR-${year}-${shortUuid}`.toUpperCase()
  }

  useEffect(() => {
    setCurrentAgreement({
      ...currentAgreement,
      agreementID: generateAgreementID(),
      companyName: companyName,
    })
  }, [])

  const submitHandler = event => {
    event.preventDefault()
    const { agreementID, ...agreementData } = currentAgreement
    if (agreementID) {
      AgreementDataService.updateAgreement(agreementID, agreementData).then(
        () => {
          resetForm()
          toggleModal()
        }
      )
    } else {
      AgreementDataService.createAgreement(agreementData).then(() => {
        resetForm()
        toggleModal()
      })
    }
  }

  const resetForm = () => {
    setCurrentAgreement({
      agreementName: "",
      specialRate: "",
      billingCycle: "",
      createdBy: "",
      createdOn: "",
      workerRates: "",
      workerType: "",
      skill: "",
      hourlyRate: "",
      finalHourlyCost: "",
      status: "",
      agreementID: "",
    })
  }

  const editAgreement = agreement => {
    setCurrentAgreement({ ...agreement, agreementID: agreement.key || "" })
    toggleModal()
  }

  const deleteAgreement = key => {
    AgreementDataService.deleteAgreement(key)
  }

  // useEffect(() => {
  //   setCurrentAgreement(current => ({
  //     ...current,
  //     companyName: companyName,
  //   }))
  // }, [companyName])

  useEffect(() => {
    const unsubscribe = OrganisationDataService.getAll(
      data => {
        setOrganisations(data)
      },
      [currentOrg]
    )

    return () => unsubscribe && unsubscribe()
  }, [])

  useEffect(() => {
    // Find the selected organisation from the organisations array
    const selectedOrganisation = organisations.find(
      org => org.key === selectedOrg
    )

    // If an organisation is selected, update the currentAgreement with the companyName
    if (selectedOrganisation) {
      setCurrentAgreement(current => ({
        ...current,
        companyName: selectedOrganisation.businessName,
      }))
    }
  }, [selectedOrg, organisations]) // Depend on selectedOrg and organisations

  return (
    <div className="page-content">
      <Button
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => toggleModal()}
      >
        Add New Agreement
      </Button>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentAgreement.agreementID
            ? "Update Agreement"
            : "Create Agreement"}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <div>Fields marked with an asterisk (*) are required.</div>
            <FormGroup>
              <Label for="agreementID">Agreement ID</Label>
              <Input
                type="text"
                name="agreementID"
                id="agreementID"
                value={currentAgreement.agreementID || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="agreementName">Agreement Name</Label>
              <Input
                type="text"
                name="agreementName"
                id="agreementName"
                value={currentAgreement.agreementName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="specialRate">Special Rate</Label>
              <Input
                type="text"
                name="specialRate"
                id="specialRate"
                value={currentAgreement.specialRate}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="billingCycle">Billing Cycle</Label>
              <Input
                type="select"
                name="billingCycle"
                id="billingCycle"
                value={currentAgreement.billingCycle}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a cycle</option>
                <option value="Daily">Daily</option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Yearly">Yearly</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="createdBy">Created by</Label>
              <Input
                type="text"
                name="createdBy"
                id="createdBy"
                value={currentAgreement.createdBy}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="createdOn">Created on</Label>
              <Input
                type="date"
                name="createdOn"
                id="createdOn"
                value={currentAgreement.createdOn}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="workerRates">Worker rates</Label>
              <Input
                type="text"
                name="workerRates"
                id="workerRates"
                value={currentAgreement.workerRates}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="workerType">Worker type</Label>
              <Input
                type="text"
                name="workerType"
                id="workerType"
                value={currentAgreement.workerType}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="skill">Skill</Label>
              <Input
                type="text"
                name="skill"
                id="skill"
                value={currentAgreement.skill}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="hourlyRate">Hourly rate</Label>
              <Input
                type="text"
                name="hourlyRate"
                id="hourlyRate"
                value={currentAgreement.hourlyRate}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="finalHourlyCost">Final Hour cost</Label>
              <Input
                type="text"
                name="finalHourlyCost"
                id="finalHourlyCost"
                value={currentAgreement.finalHourlyCost}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="companyName">Company</Label>
              <Input
                type="text"
                name="companyName"
                id="companyName"
                value={currentAgreement.companyName}
                onChange={handleInputChange}
                disabled={true}
                style={{ display: "none" }}
              />

              <select
                className="form-control"
                value={selectedOrg}
                onChange={handleSelectChange}
              >
                <option value="">Select Organisation</option>
                {organisations.map(org => (
                  <option key={org.key} value={org.key}>
                    {org.businessName}
                  </option>
                ))}
              </select>
              {/* <Input
                name="user_company"
                className="form-control"
                placeholder="Enter company"
                type="text"
               
                value={validation.values.user_company || ""}
            
                style={{ display: "none" }}
              /> */}
            </FormGroup>

            {/* <FormGroup>
              <Label for="status">Status</Label>
              <Input
                type="text"
                name="status"
                id="status"
                value={currentOrg.status}
                onChange={handleInputChange}
              />
            </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentAgreement.agreementID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Table>
        <thead>
          <tr>
            <th>Agreement Name</th>
            {/* <th>Agreement ID</th> */}
            {/* Add additional headers for other fields */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {agreement.map(agree => (
            <tr key={agree.key}>
              <td>{agree.agreementName}</td>
              {/* <td>{agree.agreementID}</td> */}
              {/* Add additional cells for other fields */}
              <td>
                <Button onClick={() => editAgreement(agree)}>Edit</Button>
                &nbsp;&nbsp;&nbsp;
                <Button onClick={() => deleteAgreement(agree.key)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default AgreementManager
