import React, { useState, useEffect } from "react"
import { Button, ListGroup, ListGroupItem } from "reactstrap"
import ActivityDetailModal from "./ActivityDetailModal"
import ActivityDataService from "./../../services/ActivityDataService" // Update with the correct path

const ActivityManager = () => {
  const [activities, setActivities] = useState([])
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    ActivityDataService.getAll(fetchedActivities => {
      setActivities(fetchedActivities)
    })
  }, [])

  const handleActivityClick = activity => {
    setSelectedActivity(activity)
    setModalOpen(true)
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <div className="page-content container">
      <h1 className="text-center mb-4">Activities</h1>
      <ListGroup>
        {activities.map((activity, index) => (
          <ListGroupItem
            key={index}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              {activity.activity} - {activity.date}
            </span>
            <Button
              color="primary"
              onClick={() => handleActivityClick(activity)}
            >
              View Details
            </Button>
          </ListGroupItem>
        ))}
      </ListGroup>

      <ActivityDetailModal
        activity={selectedActivity}
        isOpen={modalOpen}
        toggle={toggleModal}
      />
    </div>
  )
}

export default ActivityManager
