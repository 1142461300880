// Firebase imports
import { initializeApp } from 'firebase/app';
import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
  query,
  orderByChild,
  equalTo,
  get,
} from "firebase/database"


// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

class WorkersDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/worker")
  }

  getWorkersCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  getWorkersByCompanyName(companyName, callback) {
    const workersByCompanyRef = query(
      this.dbRef,
      orderByChild("companyName"),
      equalTo(companyName)
    )
    return get(workersByCompanyRef).then(snapshot => {
      const data = snapshot.val()
      let workers = []
      if (data) {
        workers = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      if (typeof callback === "function") {
        callback(workers)
      } else {
        console.error("Callback is not a function")
      }
    })
  }

  // getAll(callback) {
  //   return onValue(this.dbRef, snapshot => {
  //     const data = snapshot.val()
  //     let worker = []
  //     if (data) {
  //       worker = Object.keys(data).map(key => ({ key, ...data[key] }))
  //     }
  //     callback(worker)
  //   })
  // }

  getAll(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      let worker = []
      if (data) {
        worker = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      if (typeof callback === "function") {
        callback(worker)
      } else {
        console.error("Callback is not a function")
      }
    })
  }

  findWorkerByEmail(email) {
    return this.getAll().then(workers => {
      const foundWorker = workers.find(
        worker => worker.workerEmailAddress === email
      )
      return foundWorker
    })
  }

  createWorker(worker) {
    return push(this.dbRef, worker)
  }

  updateWorker(key, value) {
    const specificWorkerRef = ref(getDatabase(), `/worker/${key}`)
    return update(specificWorkerRef, value)
  }

  deleteWorker(key) {
    const specificWorkerRef = ref(getDatabase(), `/worker/${key}`)
    return remove(specificWorkerRef)
  }
}

export default new WorkersDataService();