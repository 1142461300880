// Firebase imports
import { initializeApp } from "firebase/app"
import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
} from "firebase/database"

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getDatabase(app)

class ActivityDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/activity")
  }

  getActivityCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  getAll(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      let activity = []
      if (data) {
        activity = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      callback(activity)
    })
  }

  createActivity(activity) {
    return push(this.dbRef, activity)
  }

  updateActivity(key, value) {
    const specificActivityRef = ref(getDatabase(), `/activity/${key}`)
    return update(specificActivityRef, value)
  }

  deleteActivity(key) {
    const specificActivityRef = ref(getDatabase(), `/activity/${key}`)
    return remove(specificActivityRef)
  }
}

export default new ActivityDataService()
