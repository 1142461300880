import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"
import JobDataService from "./../../services/JobDataService"; 
import { Link } from "react-router-dom"
 
const TopJobs = () => {
  const [topCitiesJobData, setTopCitiesJobData] = useState([])

  useEffect(() => {
    JobDataService.getAll(jobs => {
      const locationCounts = jobs.reduce((acc, job) => {
        const location = job.location
        acc[location] = (acc[location] || 0) + 1
        return acc
      }, {})

      const locationsArray = Object.entries(locationCounts)
      const totalJobs = locationsArray.reduce(
        (sum, [, count]) => sum + count,
        0
      )

      const sortedData = locationsArray
        .sort((a, b) => b[1] - a[1])
        .map(([location, count], index) => ({
          location,
          value: count,
          progress: {
            value: (count / totalJobs) * 100,
            color: getColor(index),
          },
        }))

      // Take only the top 10 locations
      const topTenData = sortedData.slice(0, 10)
      setTopCitiesJobData(topTenData)
    })
  }, [])

  const getColor = index => {
    const colors = ["primary", "success", "info", "warning", "danger"]
    return colors[index % colors.length]
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Top Jobs By Location</CardTitle>
          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                {topCitiesJobData.map((item, index) => (
                  <tr key={index}>
                    <td style={{ width: "30%" }}>
                      <p className="mb-0">{item.location}</p>
                    </td>
                    <td style={{ width: "25%" }}>
                      <h5 className="mb-0">{item.value}</h5>
                    </td>
                    <td>
                      <Progress
                        value={item.progress.value}
                        color={item.progress.color}
                        className="bg-transparent progress-sm"
                        size="sm"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-center mt-4">
            <Link to="/jobs" className="btn btn-primary">
              View More <i className="mdi mdi-arrow-right ms-1" />
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopJobs
