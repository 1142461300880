// Firebase imports
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, onValue, update, remove } from 'firebase/database';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

class OrganisationDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/organisation")
  }

  getOrganisationCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  getAll(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      let organisations = []
      if (data) {
        organisations = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      callback(organisations)
    })
  }

  getOrganisationByKey(key, callback) {
    const specificOrgRef = ref(getDatabase(), `/organisation/${key}`)
    return onValue(specificOrgRef, snapshot => {
      const data = snapshot.val()
      callback({ key, ...data }) 
    })
  }

  createOrganisation(organisation) {
    return push(this.dbRef, organisation)
  }

  updateOrganisation(key, value) {
    const specificOrgRef = ref(getDatabase(), `/organisation/${key}`)
    return update(specificOrgRef, value)
  }

  deleteOrganisation(key) {
    const specificOrgRef = ref(getDatabase(), `/organisation/${key}`)
    return remove(specificOrgRef)
  }
}

export default new OrganisationDataService();