// Firebase imports
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, onValue, update, remove, query, equalTo, orderByKey, orderByChild } from 'firebase/database';
 
// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

class JobDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/job")
  }

  getJobById(jobID, callback) {
    const jobRef = ref(getDatabase(), "/job")
    console.log(jobRef)

    const jobQuery = query(jobRef, orderByKey(), equalTo(jobID))

    return onValue(jobQuery, snapshot => {
      let jobData = null
      const data = snapshot.val()
      if (data) {
        console.log("fxn data: " + data)
        jobData = { ...data[jobID], key: jobID }
      }
      callback(jobData)
    })
  }

  getJobsByCompanyName(companyName, callback) {
    const jobsByCompanyRef = query(
      this.dbRef,
      orderByChild("companyName"),
      equalTo(companyName)
    )

    return onValue(jobsByCompanyRef, snapshot => {
      const data = snapshot.val()
      let jobs = []
      if (data) {
        jobs = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      callback(jobs)
    })
  }

  getJobCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  getAll(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      let job = []
      if (data) {
        job = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      callback(job)
    })
  }

  createJob(job) {
    return push(this.dbRef, job).then(ref => {
      // console.log("i am returning: " + ref.key);
      return ref.key // Return the key of the newly created job
    })
  }

  updateJob(key, value) {
    const specificJobRef = ref(getDatabase(), `/job/${key}`)
    return update(specificJobRef, value)
  }

  deleteJob(key) {
    const specificJobRef = ref(getDatabase(), `/job/${key}`)
    return remove(specificJobRef)
  }
}

export default new JobDataService();