import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import AgreementDataService from "services/AgreementDataService"
import { v4 as uuidv4 } from "uuid"

const AgreementClient = () => {
  const [agreement, setAgreement] = useState([])
  const [currentAgreement, setCurrentAgreement] = useState({
    agreementName: "",
    specialRate: "",
    billingCycle: "",
    createdBy: "",
    createdOn: "",
    workerRates: "",
    workerType: "",
    skill: "",
    hourlyRate: "",
    finalHourlyCost: "",
    status: "",
    agreementID: "",
  })
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");

    useEffect(() => {
      if (!localStorage.getItem("authUserDetails")) {
        // console.log("No user details found...")
      } 
      else 
      {
        // console.log("LS " + localStorage.getItem("authUserDetails"))
        if (localStorage.getItem("authUserDetails")) {
          let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

          const company = userDetails.userCompany;
          console.log("Company is: " + company);
          setCompanyName(company);
        } 
        else 
        {
          console.log("No user details found in localStorage.")
        }
      }
    }, []);
  // useEffect(() => {
  //   const unsubscribe = AgreementDataService.getAll(
  //     data => {
  //       setAgreement(data)
  //     },
  //     [currentAgreement]
  //   )

  //   return () => unsubscribe && unsubscribe()
  // }, [])

  useEffect(() => {
    // Define a function to fetch filtered agreements
    const fetchAgreements = async () => {
      if (companyName) {
        // Check if a company name has been selected
        AgreementDataService.getAgreementsByCompanyName(
          companyName,
          filteredAgreements => {
            setAgreement(filteredAgreements)
          }
        )
      }
    }

    fetchAgreements()

    // Assuming AgreementDataService does not actually provide an unsubscribe method
    // Typically, Firebase onValue listeners do provide a way to unsubscribe, but since your original code
    // structure suggests a different pattern, we will not include unsubscribe logic here
    // Implement unsubscribe logic if your service supports it
  }, [companyName]) 

  const toggleModal = () => {
    console.log(isModalOpen)
    setIsModalOpen(!isModalOpen)
    if (isModalOpen) {
      resetForm()
    }
  }

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setCurrentAgreement({ ...currentAgreement, [name]: value });
  // };

  const handleInputChange = event => {
    const { name, value } = event.target

    if (name === "agreementID") {
      const fixedPrefix = currentAgreement.agreementID.substring(0, 14)

      if (value.startsWith(fixedPrefix)) {
        setCurrentAgreement({ ...currentAgreement, [name]: value })
      }
    } else {
      setCurrentAgreement({ ...currentAgreement, [name]: value })
    }
  }

  const generateAgreementID = () => {
    const year = new Date().getFullYear()
    const uuid = uuidv4()
    const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
    return `AGR-${year}-${shortUuid}`.toUpperCase()
  }

  useEffect(() => {
    setCurrentAgreement({
      ...currentAgreement,
      agreementID: generateAgreementID(),
    })
  }, [])

  const submitHandler = event => {
    event.preventDefault()
    const { agreementID, ...agreementData } = currentAgreement
    if (agreementID) {
      AgreementDataService.updateAgreement(agreementID, agreementData).then(
        () => {
          resetForm()
          toggleModal()
        }
      )
    } else {
      AgreementDataService.createAgreement(agreementData).then(() => {
        resetForm()
        toggleModal()
      })
    }
  }

  const resetForm = () => {
    setCurrentAgreement({
      agreementName: "",
      specialRate: "",
      billingCycle: "",
      createdBy: "",
      createdOn: "",
      workerRates: "",
      workerType: "",
      skill: "",
      hourlyRate: "",
      finalHourlyCost: "",
      status: "",
      agreementID: "",
    })
  }

  const editAgreement = agreement => {
    setCurrentAgreement({ ...agreement, agreementID: agreement.key || "" })
    toggleModal()
  }

  const deleteAgreement = key => {
    AgreementDataService.deleteAgreement(key)
  }

  return (
    <div className="page-content">
      {/* <Button
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => toggleModal()}
      >
        Add New Agreement
      </Button> */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentAgreement.agreementID
            ? "Agreement"
            : "Create Agreement"}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <div>Fields marked with an asterisk (*) are required.</div>
            <FormGroup>
              <Label for="agreementID">Agreement ID</Label>
              <Input
                type="text"
                name="agreementID"
                id="agreementID"
                value={currentAgreement.agreementID || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="agreementName">Agreement Name</Label>
              <Input
                type="text"
                name="agreementName"
                id="agreementName"
                value={currentAgreement.agreementName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="specialRate">Special Rate</Label>
              <Input
                type="text"
                name="specialRate"
                id="specialRate"
                value={currentAgreement.specialRate}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="billingCycle">Billing Cycle</Label>
              <Input
                type="select"
                name="billingCycle"
                id="billingCycle"
                value={currentAgreement.billingCycle}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a cycle</option>
                <option value="Daily">Daily</option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Yearly">Yearly</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="createdBy">Created by</Label>
              <Input
                type="text"
                name="createdBy"
                id="createdBy"
                value={currentAgreement.createdBy}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="createdOn">Created on</Label>
              <Input
                type="date"
                name="createdOn"
                id="createdOn"
                value={currentAgreement.createdOn}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="workerRates">Worker rates</Label>
              <Input
                type="text"
                name="workerRates"
                id="workerRates"
                value={currentAgreement.workerRates}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="workerType">Worker type</Label>
              <Input
                type="text"
                name="workerType"
                id="workerType"
                value={currentAgreement.workerType}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="skill">Skill</Label>
              <Input
                type="text"
                name="skill"
                id="skill"
                value={currentAgreement.skill}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="hourlyRate">Hourly rate</Label>
              <Input
                type="text"
                name="hourlyRate"
                id="hourlyRate"
                value={currentAgreement.hourlyRate}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="finalHourlyCost">Final Hour cost</Label>
              <Input
                type="text"
                name="finalHourlyCost"
                id="finalHourlyCost"
                value={currentAgreement.finalHourlyCost}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            {/* <FormGroup>
              <Label for="status">Status</Label>
              <Input
                type="text"
                name="status"
                id="status"
                value={currentOrg.status}
                onChange={handleInputChange}
              />
            </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" type="submit">
              {currentAgreement.agreementID ? "Update" : "Create"}
            </Button> */}
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Table>
        <thead>
          <tr>
            <th>Agreement Name</th>
            {/* <th>Agreement ID</th> */}
            {/* Add additional headers for other fields */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {agreement.map(agree => (
            <tr key={agree.key}>
              <td>{agree.agreementName}</td>
              {/* <td>{agree.agreementID}</td> */}
              {/* Add additional cells for other fields */}
              <td>
                <Button onClick={() => editAgreement(agree)}>View agreement</Button>
                &nbsp;&nbsp;&nbsp;
                {/* <Button onClick={() => deleteAgreement(agree.key)}>
                  Delete
                </Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default AgreementClient
