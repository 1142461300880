import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

const ActivityDetailModal = ({ activity, isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Activity Details</ModalHeader>
      <ModalBody>
        <p>
          <strong>Date:</strong> {activity?.date}
        </p>
        <p>
          <strong>Time:</strong> {activity?.time}
        </p>
        <p>
          <strong>User:</strong> {activity?.user}
        </p>
        <p>
          <strong>User Type:</strong> {activity?.userType}
        </p>
        <p>
          <strong>Activity:</strong> {activity?.activity}
        </p>
      </ModalBody>
    </Modal>
  )
}

export default ActivityDetailModal
