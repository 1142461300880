import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import JobDataService from "services/JobDataService"
import { v4 as uuidv4 } from "uuid"

const JobWorker = () => {
  const [job, setJob] = useState([])
  const [company, setCompany] = useState("")
  const [currentJob, setCurrentJob] = useState({
    jobTitle: "",
    jobDescription: "",
    jobExperience: "",
    jobExperience: "",
    jobType: "",
    provider: "",
    careHome: "",
    workerType: "",
    noofVacancies: "",
    startDate: "",
    startTime: "",
    endTime: "",
    endDate: "",
    enableRecurrence: "",
    breaks: "",
    jobstatus: "",
    jobID: "",
    category: "",
    featured: "",
  })
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    if (!isModalOpen) {
      // Set provider when opening the modal
      setCurrentJob(prevJob => ({ ...prevJob, provider: company }))
    } else {
      resetForm()
    }
  }

  useEffect(() => {
    if (localStorage.getItem("authUserDetails")) {
      let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

      let userCompany = userDetails.userCompany

      if (userCompany) setCompany(userCompany)
    } else {
      console.log("No user details found in localStorage.")
    }
  }, [])

//   useEffect(() => {
//     setInputValue(company)
//   }, [])

  useEffect(() => {
    const unsubscribe = JobDataService.getAll(data => {
      const filteredJobs = data.filter(job => job.provider === company)
      setJob(filteredJobs)
    })
    return () => unsubscribe && unsubscribe()
  }, [company])

  const handleInputChange = event => {
    const { name, value } = event.target

    if (name === "jobID") {
      const fixedPrefix = currentJob.jobID.substring(0, 14)

      if (value.startsWith(fixedPrefix)) {
        setCurrentJob({ ...currentJob, [name]: value })
      }
    } else if (name === "provider") {
      currentJob.provider = company

      setCurrentJob(prevJob => ({ ...prevJob, provider: company }))
    } else {
      // For all other fields
      setCurrentJob({ ...currentJob, [name]: value })
    }
  }

  const submitHandler = event => {
    event.preventDefault()

    const { jobID, ...jobData } = currentJob

    jobData.provider = company

    if (jobID) {
      JobDataService.updateJob(jobID, jobData).then(() => {
        resetForm()
        toggleModal()
      })
    } else {
      // Create new job
      JobDataService.createJob(jobData).then(newJobID => {
        setCurrentJob({ ...jobData, provider: company, jobID: newJobID })

        resetForm()
        toggleModal()
      })
    }
  }


          const generatejobID = () => {
            const year = new Date().getFullYear()
            const uuid = uuidv4()
            const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
            return `JOB-${year}-${shortUuid}`.toUpperCase()
          }
          
  useEffect(() => {
    setCurrentJob({ ...currentJob, jobID: generatejobID() })
  }, [])

  const resetForm = () => {
    setCurrentJob({
      jobTitle: "",
      jobDescription: "",
      jobExperience: "",
      jobType: "",
      //provider: "",
      careHome: "",
      workerType: "",
      noofVacancies: "",
      startDate: "",
      startTime: "",
      endTime: "",
      endDate: "",
      enableRecurrence: "",
      breaks: "",
      jobstatus: "",
      jobID: "",
      category: "",
    })
  }

  const editJob = job => {
    setCurrentJob({ ...job, jobID: job.key || "" })
    toggleModal()
  }

  const deleteJob = key => {
    JobDataService.deleteJob(key)
  }

  return (
    <div className="page-content">
      <Button
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => toggleModal()}
      >
        Add New Job
      </Button>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentJob.jobID ? "Update Job" : "Create Job"}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="jobID">Job ID</Label>
              <Input
                type="text"
                name="jobID"
                id="jobID"
                value={currentJob.jobID || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="jobTitle">
                Job Title<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="jobTitle"
                id="jobTitle"
                value={currentJob.jobTitle}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="jobDescription">
                Job Description<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="textarea"
                name="jobDescription"
                id="jobDescription"
                value={currentJob.jobDescription}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="jobExperience">
                Job Experience<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="jobExperience"
                id="jobExperience"
                value={currentJob.jobExperience}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="jobType">
                Job Type<span style={{ color: "red" }}>*</span>
              </Label>

              <Input
                type="select"
                name="jobType"
                id="jobType"
                value={currentJob.jobType}
                onChange={handleInputChange}
              >
                <option value="">Select job type</option>
                <option value="Support worker">Support worker</option>
                <option value="Residential Support worker(sleep in)">
                  Residential Support worker(sleep in)
                </option>
                <option value="Health Care Assistant">
                  Health Care Assistant
                </option>
                <option value="Registered Mental Health Nurse">
                  Registered Mental Health Nurse
                </option>
                <option value="Registered General Nurse">
                  Registered General Nurse
                </option>
                <option value="Doctor">Doctor</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="provider">Provider</Label>
              <Input
                type="text"
                name="provider"
                id="provider"
                value={company}
                disabled={true}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="careHome">Care Home</Label>
              <Input
                type="text"
                name="careHome"
                id="careHome"
                value={currentJob.careHome}
                onChange={handleInputChange}
              />
            </FormGroup>

            {/* <FormGroup>
              <Label for="workerType">Worker Type</Label>
              <Input
                type="select"
                name="workerType"
                id="workerType"
                value={currentJob.workerType}
                onChange={handleInputChange}
              >
                <option value="">Select a worker type</option>
                <option value="Temporary">Temporary</option>
                <option value="Permanent">Permanent</option>
              </Input>
            </FormGroup> */}
            {/* 
            <FormGroup>
              <Label for="noofVacancies">Number of vacancies</Label>
              <Input
                type="number"
                name="noofVacancies"
                id="noofVacancies"
                value={currentJob.noofVacancies}
                onChange={handleInputChange}
              />
            </FormGroup> */}

            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <Input
                type="date"
                name="startDate"
                id="startDate"
                value={currentJob.startDate}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="startTime">Start Time</Label>
              <Input
                type="time"
                name="startTime"
                id="startTime"
                value={currentJob.startTime}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="endDate">
                End Date<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="date"
                name="endDate"
                id="endDate"
                value={currentJob.endDate}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="endTime">End time</Label>
              <Input
                type="time"
                name="endTime"
                id="endTime"
                value={currentJob.endTime}
                onChange={handleInputChange}
              />
            </FormGroup>
            {/* 
            <FormGroup>
              <Label for="enableRecurrence">Enable Recurrence</Label>

              <Input
                type="select"
                name="enableRecurrence"
                id="enableRecurrence"
                value={currentJob.enableRecurrence}
                onChange={handleInputChange}
              >
                <option value="">Enable Recurrence</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Input>
            </FormGroup> */}

            <FormGroup>
              <Label for="breaks">Breaks</Label>
              <Input
                type="number"
                name="breaks"
                id="breaks"
                value={currentJob.breaks}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="category">
                Category<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="select"
                name="category"
                id="category"
                value={currentJob.category}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a Category</option>
                <option value="Clinical">Clinical</option>
                <option value="Non-Clinical">Non-Clinical</option>
              </Input>
            </FormGroup>

            {/* <FormGroup>
              <Label for="jobstatus">Status</Label>
              <Input
                type="select"
                name="jobstatus"
                id="jobstatus"
                value={currentJob.jobstatus}
                onChange={handleInputChange}
              >
                <option value="">Select a status</option>
                <option value="Open">Open</option>
                <option value="Close">Close</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="featured">Featured</Label>
              <Input
                type="select"
                name="featured"
                id="jobstatus"
                value={currentJob.featured}
                onChange={handleInputChange}
              >
                <option value="">Featured?</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Input>
            </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentJob.jobID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Table>
        <thead>
          <tr>
            <th>Job Type</th>
            <th>Care Home</th>
            {/* Add additional headers for other fields */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {job.map(jb => (
            <tr key={jb.key}>
              <td>{jb.jobType}</td>
              <td>{jb.careHome}</td>
              {/* Add additional cells for other fields */}
              <td>
                <Button onClick={() => editJob(jb)}>Edit</Button>
                &nbsp;&nbsp;&nbsp;
                <Button onClick={() => deleteJob(jb.key)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default JobWorker
