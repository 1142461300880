// Firebase imports
import { initializeApp } from 'firebase/app';
import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
  query,
  orderByChild,
  equalTo,
  get,
} from "firebase/database"

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

class AgreementDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/agreement")
  }

  getAgreementsCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  getAgreementsByCompanyName(companyName, callback) {
    const agreementsByCompanyRef = query(
      this.dbRef,
      orderByChild("companyName"),
      equalTo(companyName)
    )
    return get(agreementsByCompanyRef).then(snapshot => {
      const data = snapshot.val()
      let agreements = []
      if (data) {
        agreements = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      if (typeof callback === "function") {
        callback(agreements)
      } else {
        console.error("Callback is not a function")
      }
    })
  }

  getAll(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      let agreement = []
      if (data) {
        agreement = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      callback(agreement)
    })
  }

  createAgreement(agreement) {
    return push(this.dbRef, agreement)
  }

  updateAgreement(key, value) {
    const specificAgreementRef = ref(getDatabase(), `/agreement/${key}`)
    return update(specificAgreementRef, value)
  }

  deleteAgreement(key) {
    const specificAgreementRef = ref(getDatabase(), `/agreement/${key}`)
    return remove(specificAgreementRef)
  }
}

export default new AgreementDataService();