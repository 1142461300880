import React, { useState, useEffect, useRef } from "react"
import JobDataService from "services/JobDataService"
import {
  DayPilot,
  DayPilotCalendar,
  DayPilotNavigator,
} from "@daypilot/daypilot-lite-react"

const Schedule = () => {
  const [jobs, setJobs] = useState([])
  const jobsRef = useRef(jobs) // Ref to hold the current jobs state
  const calendarRef = useRef()
  const [config, setConfig] = useState({
    viewType: "Week",
    timeRangeSelectedHandling: "Enabled",
    eventMoveHandling: "Update",
    onEventMoved: args => {
      console.log("Event moved: " + args.e.text())
    },
    eventResizeHandling: "Update",
    onEventResized: args => {
      console.log("Event resized: " + args.e.text())
    },
    eventClickHandling: "Enabled",
    eventHoverHandling: "Bubble",
    // Initial event handlers can be defined here if necessary
  })

  useEffect(() => {
    JobDataService.getAll(jobsData => {
      const events = Object.keys(jobsData)
        .map(key => {
          const job = jobsData[key]
          if (
            !job.startDate ||
            !job.startTime ||
            !job.endDate ||
            !job.endTime
          ) {
            console.error(`Job ${key} is missing date or time information.`)
            return null
          }
          return {
            id: key,
            text: job.careHome || "No Provider",
            start: `${job.startDate}T${job.startTime}:00`,
            end: `${job.endDate}T${job.endTime}:00`,
            backColor: "#6aa84f",
            data: job,
          }
        })
        .filter(event => event !== null)

      setJobs(events) // Update the jobs state
      calendarRef.current.control.update({ events })
    })
  }, [])

  useEffect(() => {
    jobsRef.current = jobs // Update the ref every time the jobs state changes
  }, [jobs])

  // Update the config with the latest event handlers using the jobsRef
  useEffect(() => {
    setConfig(currentConfig => ({
      ...currentConfig,
      onEventClick: async args => {
        const clickedJob = jobsRef.current.find(job => job.id === args.e.id())
        if (clickedJob) {
          // Replacing \n with <br> for HTML line breaks
          const jobDetails = `Provider: ${clickedJob.text}<br>Description: ${clickedJob.data.jobDescription}`
          // Ensure DayPilot.Modal.alert can interpret HTML for line breaks
          await DayPilot.Modal.alert(jobDetails, { html: true })
        } else {
          await DayPilot.Modal.alert("Job details not found.")
        }
      },
    }))
  }, [jobs])

  return (
    <div className="page-content">
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "10px" }}>
          <DayPilotNavigator
            selectMode="Week"
            showMonths={3}
            skipMonths={3}
            selectionDay={"2024-02-13"}
            onTimeRangeSelected={args => {
              calendarRef.current.control.update({ startDate: args.day })
            }}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <DayPilotCalendar {...config} ref={calendarRef} />
        </div>
      </div>
    </div>
  )
}

export default Schedule
