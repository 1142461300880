import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore"

import TimesheetDataService from "services/TimesheetDataService"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { editProfile } from "store/actions"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import style from "./timesheetmanager.css"
import { v4 as uuidv4 } from "uuid"


const TimesheetManager = () => {
  const [timesheet, setTimesheet] = useState([])
  const [dropdownOpenStates, setDropdownOpenStates] = useState({})
  const [emailError, setEmailError] = useState("")

  const storage = getStorage()
  const firestore = getFirestore()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDocModalOpen, setIsDocModalOpen] = useState(false)

    const [currentTimesheet, setCurrentTimesheet] = useState({
    timesheetID:"",
    fromDuration: "",
    toDuration: "",
    status: "",
    workerID: "",
  })

    useEffect(() => {
    const unsubscribe = TimesheetDataService.getAll(
      data => {
        setTimesheet(data)
      },
      [currentTimesheet]
    )

    return () => unsubscribe && unsubscribe()
  }, [])


  const toggleDocModal = () => {
    setIsDocModalOpen(!isDocModalOpen)

    if (isDocModalOpen) {
      resetDocForm()
    }
  }
    const toggleModal = () => {
    setIsModalOpen(!isModalOpen)

    if (isModalOpen) {
      resetForm()
    }
  }

    const handleInputChange = event => {
    const { name, value } = event.target

    if (name === "workerEmailAddress") {
      if (!validateEmail(value)) {
        // Set error state
        setEmailError("Invalid email address")
        //return;
      } else {
        setEmailError("")
      }
    }

    setCurrentTimesheet({ ...currentTimesheet, [name]: value })
  }

    function getValidDate(date) {
    if (!date) return null // Return null if date is not set

    const parsedDate = new Date(date)
    return isNaN(parsedDate.getTime()) ? null : parsedDate
  }


          const generateTimesheetID = () => {
            const year = new Date().getFullYear()
            const uuid = uuidv4()
            const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
            return `TST-${year}-${shortUuid}`.toUpperCase()
          }

        useEffect(() => {
          setCurrentTimesheet({
            ...currentTimesheet,
            timesheetID: generateTimesheetID(),
          })
        }, [])


    const resetForm = () => {
    setCurrentTimesheet({
      fromDuration: "",
      toDuration: "",
      status: "",
      timesheetID: "",
      workerID: "",
    })
  }

    const submitHandler = event => {
    event.preventDefault();
    console.log(currentTimesheet.timesheetID);

    const { timesheetID, ...timesheetData } = currentTimesheet
      if (timesheetID) {
      TimesheetDataService.updateTimesheet(timesheetID, timesheetData).then(() => {
        resetForm()
        toggleModal()
      })
    } 
    else 
    {
      TimesheetDataService.createTimesheet(timesheetData).then(() => {
        resetForm()
        toggleModal()
      })
    }
    }
    const toggleDropdown = key => {
    setDropdownOpenStates(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }))
  }

  const editTimesheet = timesheet => {
    setCurrentTimesheet({ ...timesheet, timesheetID: timesheet.key || "" })
    toggleModal()
  }

      return (
        <div className="page-content">
          <div className="doc-container">
            <Button
              style={{ margin: "10px" }}
              color="primary"
              onClick={() => {
                toggleModal()
              }}
            >
              Add New Timesheet
            </Button>
          </div>

          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
              {currentTimesheet.timesheetID
                ? "Update Timesheet"
                : "Create Timesheet"}
            </ModalHeader>
            <Form onSubmit={submitHandler}>
              <ModalBody>
                <FormGroup>
                  <Label for="timesheetID">Timesheet ID</Label>
                  <Input
                    type="text"
                    name="timesheetID"
                    id="timesheetID"
                    value={currentTimesheet.timesheetID || ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="fromDuration">Start time:</Label>
                  <Input
                    type="time"
                    name="fromDuration"
                    id="fromDuration"
                    value={currentTimesheet.fromDuration}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="toDuration">End time:</Label>
                  <Input
                    type="time"
                    name="toDuration"
                    id="toDuration"
                    value={currentTimesheet.toDuration}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="workerID">Worker ID:</Label>
                  <Input
                    type="text"
                    name="workerID"
                    id="workerID"
                    value={currentTimesheet.workerID}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="status">Status</Label>
                  <select
                    name="status"
                    value={currentTimesheet.status}
                    onChange={handleInputChange}
                    className="form-control"
                  >
                    <option value="">Select status</option>
                    <option value="New">New</option>
                    <option value="Progress">In Progress</option>
                    <option value="Approved">Approved</option>
                  </select>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit">
                  {currentTimesheet.timesheetID ? "Update" : "Create"}
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Table>
            <thead>
              <tr>
                <th>Timesheet ID</th>
                <th>From</th>
                <th>To</th>
                <th>Status</th>
                {/* Add additional headers for other fields */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {timesheet.map(work => (
                <tr key={work.key}>
                  <td>{work.key}</td>
                  <td>{work.fromDuration}</td>
                  <td>{work.toDuration}</td>
                  <td>
                    <span
                      className={`badge badge-${
                        (work.status && work.status.toLowerCase()) || "new"
                      }`}
                    >
                      {work.status || "New"}
                    </span>
                  </td>
                  {/* Add additional cells for other fields */}
                  <td>
                    {/* <Button onClick={() =>{editWorker(work)}}>Edit</Button>&nbsp;&nbsp;&nbsp;
                <Button onClick={() => deleteWorker(work.key)}>Delete</Button> */}
                    <Dropdown
                      className="custom-dropdown-menu"
                      isOpen={dropdownOpenStates[work.key]}
                      toggle={() => toggleDropdown(work.key)}
                    >
                      <DropdownToggle caret>Actions</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => editTimesheet(work)}>
                          Edit
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )
}

export default TimesheetManager
