// activityLogger.js

import ActivityDataService from "./../services/ActivityDataService" // Adjust the path as needed

const logUserActivity = ({ date, time, activity, user, userType }) => {
  // Create an activity object
  const activityLog = {
    date,
    time,
    activity,
    user,
    userType,
  }

  // Use ActivityDataService to save this activity log
  ActivityDataService.createActivity(activityLog)
    .then(() => {
      console.log("Activity logged successfully")
    })
    .catch(error => {
      console.error("Error logging activity:", error)
    })
}

export default logUserActivity
