import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import OrganisationDataService from "services/OrganisationDataService"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { getFirestore, doc, setDoc } from "firebase/firestore"
import style from "./organisationmanager.css"
import { v4 as uuidv4 } from "uuid";


const OrganisationClient = () => {
  const [organisations, setOrganisations] = useState([]);
    
  const [organisation, setOrganisation] = useState([])

  const [dropdownOpenStates, setDropdownOpenStates] = useState({});
  const [emailError, setEmailError] = useState("");

  const [organisationKey, setorganisationKey
  ] = useState("")

  const storage = getStorage();
  const firestore = getFirestore();

  const [currentOrg, setCurrentOrg] = useState({
    businessName: "",
    alsoKnownAs: "",
    businessRegNumber: "",
    agencyPostCode: "",
    registeredOfficeAddress: "",
    businessPhoneNumber: "",
    businessMobileNumber: "",
    companyLogo: "",
    areYouVATRegistered: "",
    vatNumber: "",
    organisationalStatus: "",
    businessID: "",

    businessLocation: "",
    city: "",
    businessEmailAddress: "",

    staffLocation: "",
    noOfWorkers: "",
    monthlyHoursAvailable: "",
    employeeType: "",
    workerType: "",
    workerSkills: "",
    minChargeHourlyRate: "",

    billAddress: "",
    costCentre: "",
    digitalBillingAddress: "",
    digitalccBillingAddress: "",
    billingCycle: "",
    creditTerm: "",
    bankName: "",
    accountNumber: "",
    accountName: "",
    sortCode: "",

    userName: "",
    userEmail: "",
    userType: "",
    userCity: "",
    userJobTitle: "",
    userPhoneNumber: "",
    userLocation: "",

    agencyUserList: [],

    subContractingAgreement: null,
    workersList: [],

    settings: "",
  })
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [isUsersModalOpen, setIsUsersModalOpen] = useState(false);
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [isWorkersModalOpen, setIsWorkersModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [locationValues, setLocationValues] = useState([]);


  useEffect(() => {
    if (!localStorage.getItem("authUserDetails")) {
      // console.log("No user details found...")
    } else {
      // console.log("LS " + localStorage.getItem("authUserDetails"))
      if (localStorage.getItem("authUserDetails")) {
        let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))
      // console.log("User details found..." + userDetails.userCompany)
        setorganisationKey(userDetails.userCompany)
      } else {
        // console.log("No user details found in localStorage.")
      }
    }
  }, []);


  useEffect(() => {
    OrganisationDataService.getOrganisationByKey(organisationKey, data => {
      setOrganisation(data);
      // console.log("Organisation data: " + JSON.stringify(data));
    })
  }, [organisationKey]) 

  // useEffect(() => {
  //   const unsubscribe = OrganisationDataService.getAll(
  //     data => {
  //       setOrganisations(data)
  //     },
  //     [currentOrg]
  //   )

  //   return () => unsubscribe && unsubscribe()
  // }, [])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    if (isModalOpen) {
      resetForm()
    }
  }

  const toggleLocationModal = () => {
    setIsLocationModalOpen(!isLocationModalOpen)
    if (isLocationModalOpen) {
      resetLocationForm()
    }
  }

  const toggleStaffModal = () => {
    setIsStaffModalOpen(!isStaffModalOpen)
    if (isStaffModalOpen) {
      resetStaffForm()
    }
  }

  const toggleBillingModal = () => {
    setIsBillingModalOpen(!isBillingModalOpen)
    if (isBillingModalOpen) {
      resetBillingForm()
    }
  }

  const toggleUsersModal = () => {
    setIsUsersModalOpen(!isUsersModalOpen)
    if (isUsersModalOpen) {
      resetUsersForm()
    }
  }

  const toggleAgreementModal = () => {
    setIsAgreementModalOpen(!isAgreementModalOpen)
    if (isAgreementModalOpen) {
      resetAgreementForm()
    }
  }

  const toggleWorkersModal = () => {
    setIsWorkersModalOpen(!isWorkersModalOpen)
    if (isWorkersModalOpen) {
      resetWorkersForm()
    }
  }

  const toggleSettingsModal = () => {
    setIsSettingsModalOpen(!isSettingsModalOpen)
    if (isSettingsModalOpen) {
      resetSettingsForm()
    }
  }

  function validateEmail(email) {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return re.test(String(email).toLowerCase())
  }

  const handleInputChange = event => {
    const { name, value } = event.target

    // Check for email fields and validate
    if (
      name === "businessEmailAddress" ||
      name === "digitalBillingAddress" ||
      name === "digitalccBillingAddress"
    ) {
      if (!validateEmail(value)) {
        // Set error state
        setEmailError("Invalid email address")
      } else {
        setEmailError("")
      }
    }

    // Check for businessID and ensure first 8 characters are not altered
    if (name === "businessID") {
      const fixedPrefix = currentOrg.businessID.substring(0, 14)

      if (value.startsWith(fixedPrefix)) {
        setCurrentOrg({ ...currentOrg, [name]: value })
      }
      // If the check fails, do not update the state. You might want to handle this case.
    } else {
      // For all other fields
      setCurrentOrg({ ...currentOrg, [name]: value })
    }
  }

  const handleFileChange = event => {
    const { name, files } = event.target
    if (files.length > 0) {
      setCurrentOrg(prevState => ({
        ...prevState,
        [name]: files[0],
      }))
    }
  }

  function isValidUrl(string) {
    try {
      new URL(string)
      return true
    } catch (_) {
      return false
    }
  }

  const submitHandler = async event => {
    event.preventDefault()
    const { businessID, companyLogo, ...organisationData } = currentOrg

    try {
      const uploadDocument = async (document, path) => {
        if (document) {
          const documentRef = ref(
            storage,
            `companydocuments/${businessID}/${path}`
          )
          await uploadBytes(documentRef, document)
          return getDownloadURL(documentRef)
        }
        return null
      }

      const logo = await uploadDocument(companyLogo, "logo")

      const updatedData = {
        ...organisationData,
        companyLogo: logo,
      }

      if (businessID) {
        OrganisationDataService.updateOrganisation(
          businessID,
          updatedData
        ).then(() => {
          resetForm()
          toggleModal()
        })
      } else {
        OrganisationDataService.createOrganisation(updatedData).then(() => {
          resetForm()
          toggleModal()
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const submitLocationHandler = event => {
    event.preventDefault()
    const { businessID, ...organisationData } = currentOrg
    if (businessID) {
      OrganisationDataService.updateOrganisation(
        businessID,
        organisationData
      ).then(() => {
        resetLocationForm()
        toggleLocationModal()
      })
    } else {
      OrganisationDataService.createOrganisation(organisationData).then(() => {
        resetLocationForm()
        toggleLocationModal()
      })
    }
  }

  const submitStaffHandler = event => {
    event.preventDefault()
    const { businessID, ...organisationData } = currentOrg
    if (businessID) {
      OrganisationDataService.updateOrganisation(
        businessID,
        organisationData
      ).then(() => {
        resetStaffForm()
        toggleStaffModal()
      })
    } else {
      OrganisationDataService.createOrganisation(organisationData).then(() => {
        resetStaffForm()
        toggleStaffModal()
      })
    }
  }

  const submitBillingHandler = event => {
    event.preventDefault()
    const { businessID, ...organisationData } = currentOrg
    if (businessID) {
      OrganisationDataService.updateOrganisation(
        businessID,
        organisationData
      ).then(() => {
        resetBillingForm()
        toggleBillingModal()
      })
    } else {
      OrganisationDataService.createOrganisation(organisationData).then(() => {
        resetBillingForm()
        toggleBillingModal()
      })
    }
  }

  const submitUsersHandler = event => {
    event.preventDefault()
    const { businessID, ...organisationData } = currentOrg
    if (businessID) {
      OrganisationDataService.updateOrganisation(
        businessID,
        organisationData
      ).then(() => {
        resetUsersForm()
        toggleUsersModal()
      })
    } else {
      OrganisationDataService.createOrganisation(organisationData).then(() => {
        resetUsersForm()
        toggleUsersModal()
      })
    }
  }

        const generateBusinessID = () => {
          const year = new Date().getFullYear()
          const uuid = uuidv4()
          const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
          return `ORG-${year}-${shortUuid}`.toUpperCase()
        }

  useEffect(() => {
    setCurrentOrg({ ...currentOrg, businessID: generateBusinessID() })
  }, [])

  const submitAgreementHandler = async event => {
    event.preventDefault()

    const { businessID, subContractingAgreement, ...otherData } = currentOrg

    try {
      if (businessID) {
        // Upload each document and get their download URLs
        const uploadDocument = async (document, path) => {
          if (document) {
            const documentRef = ref(storage, `documents/${businessID}/${path}`)
            await uploadBytes(documentRef, document)
            return getDownloadURL(documentRef)
          }
          return null
        }

        //console.log("Uploading document: " + subContractingAgreement);

        const subContractingAgreementUrl = await uploadDocument(
          subContractingAgreement,
          "subContractingAgreement"
        )

        //console.log("Uploading document: " + subContractingAgreementUrl);

        // Create an object to update in Firestore
        const updatedData = {
          ...otherData,
          subContractingAgreement: subContractingAgreementUrl,
        }

        OrganisationDataService.updateOrganisation(
          businessID,
          updatedData
        ).then(() => {
          resetAgreementForm()
          toggleAgreementModal()
        })
      } else {
        console.log("Organisation ID is required")
      }
    } catch (error) {
      console.log("Update error: " + error.message)
    }
  }

  const submitWorkersHandler = event => {
    event.preventDefault()
    const { businessID, ...organisationData } = currentOrg
    if (businessID) {
      OrganisationDataService.updateOrganisation(
        businessID,
        organisationData
      ).then(() => {
        resetWorkersForm()
        toggleWorkersModal()
      })
    } else {
      OrganisationDataService.createOrganisation(organisationData).then(() => {
        resetWorkersForm()
        toggleWorkersModal()
      })
    }
  }

  const submitSettingsHandler = event => {
    event.preventDefault()
    const { businessID, ...organisationData } = currentOrg
    if (businessID) {
      OrganisationDataService.updateOrganisation(
        businessID,
        organisationData
      ).then(() => {
        resetSettingsForm()
        toggleSettingsModal()
      })
    } else {
      OrganisationDataService.createOrganisation(organisationData).then(() => {
        resetSettingsForm()
        toggleSettingsModal()
      })
    }
  }

  const resetForm = () => {
    setCurrentOrg({
      businessName: "",
      alsoKnownAs: "",
      businessRegNumber: "",
      agencyPostCode: "",
      registeredOfficeAddress: "",
      businessPhoneNumber: "",
      businessMobileNumber: "",
      companyLogo: "",
      areYouVATRegistered: "",
      vatNumber: "",
      organisationalStatus: "",
      businessID: "",
    })
  }

  const resetLocationForm = () => {
    setCurrentOrg({
      businessLocation: "",
      city: "",
      businessEmailAddress: "",
    })
  }

  const resetStaffForm = () => {
    setCurrentOrg({
      staffLocation: "",
      noOfWorkers: "",
      monthlyHoursAvailable: "",
      employeeType: "",
      workerType: "",
      workerSkills: "",
      minChargeHourlyRate: "",
    })
  }

  const resetBillingForm = () => {
    setCurrentOrg({
      billAddress: "",
      costCentre: "",
      digitalBillingAddress: "",
      digitalccBillingAddress: "",
      billingCycle: "",
      creditTerm: "",
      bankName: "",
      accountNumber: "",
      accountName: "",
      sortCode: "",
    })
  }

  const resetUsersForm = () => {
    setCurrentOrg({
      agencyUserList: [],
    })
  }

  const resetAgreementForm = () => {
    setCurrentOrg({
      subagreementID: "",
      subContractingAgreement: "",
    })
  }

  const resetWorkersForm = () => {
    setCurrentOrg({
      workersList: [],
    })
  }

  const resetSettingsForm = () => {
    setCurrentOrg({
      settings: "",
    })
  }

  const editOrganisation = org => {
    setCurrentOrg({ ...org, businessID: org.key || "" })

    toggleModal()
  }

  const editLocation = org => {
    setCurrentOrg({ ...org, businessID: org.key || "" })

    toggleLocationModal()
  }

  const editStaff = org => {
    setCurrentOrg({ ...org, businessID: org.key || "" })

    toggleStaffModal()
  }

  const editBilling = org => {
    setCurrentOrg({ ...org, businessID: org.key || "" })

    toggleBillingModal()
  }

  const editUsers = org => {
    setCurrentOrg({ ...org, businessID: org.key || "" })

    toggleUsersModal()
  }

  const editAgreement = org => {
    setCurrentOrg({ ...org, businessID: org.key || "" })

    toggleAgreementModal()
  }

  const editWorkers = org => {
    setCurrentOrg({ ...org, businessID: org.key || "" })

    toggleWorkersModal()
  }

  const editSettings = org => {
    setCurrentOrg({ ...org, businessID: org.key || "" })

    toggleSettingsModal()
  }

  const deleteOrganisation = key => {
    OrganisationDataService.deleteOrganisation(key)
  }

  const toggleDropdown = key => {
    setDropdownOpenStates(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }))
  }

  let locvalues = [
    "Aberdeen",
    "Anglesey",
    "Angus",
    "Argyll",
    "Ayr",
    "Banff",
    "Bedfordshire",
    "Berkshire",
    "Berwick",
    "Breconshire",
    "Buckinghamshire",
    "Bute",
    "Caernarvonshire",
    "Caithness",
    "Cambridgeshire",
    "Cardiganshire",
    "Carmarthenshire",
    "Cheshire",
    "City of Aberdeen",
    "City of Dundee",
    "City of Edinburgh",
    "City of Glasgow",
    "Clackmannan",
    "Cornwall and the Isles of Scilly",
    "Cumberland",
    "Denbighshire",
    "Derbyshire",
    "Devon",
    "Dorset",
    "Dumfries",
    "Dunbarton",
    "Durham",
    "East Lothian",
    "East Suffolk",
    "East Sussex",
    "Ely, Isle of",
    "Essex",
    "Fife",
    "Flintshire",
    "Glamorgan",
    "Gloucestershire",
    "Hampshire",
    "Herefordshire",
    "Hertfordshire",
    "Huntingdonshire",
    "Inverness",
    "Kent",
    "Kincardine",
    "Kinross",
    "Kirkcudbright",
    "Lanark",
    "Lancashire",
    "Leicestershire",
    "Lincolnshire (Parts of Holland)",
    "Lincolnshire (Parts of Kesteven)",
    "Lincolnshire (Parts of Lindsey)",
    "London",
    "Merionethshire",
    "Middlesex",
    "Midlothian",
    "Monmouthshire",
    "Montgomeryshire",
    "Moray",
    "Nairn",
    "Norfolk",
    "Northamptonshire",
    "Northumberland",
    "Nottinghamshire",
    "Orkney",
    "Oxfordshire",
    "Peebles",
    "Pembrokeshire",
    "Perth",
    "Radnorshire",
    "Renfrew",
    "Ross and Cromarty",
    "Roxburgh",
    "Rutland",
    "Selkirk",
    "Shropshire",
    "Soke of Peterborough",
    "Somerset",
    "Staffordshire",
    "Stirling",
    "Surrey",
    "Sutherland",
    "Warwickshire",
    "West Lothian",
    "West Suffolk",
    "West Sussex",
    "Westmorland",
    "Wight, Isle of",
    "Wigtown",
    "Wiltshire",
    "Worcestershire",
    "Yorkshire (East Riding)",
    "Yorkshire (North Riding)",
    "Yorkshire (West Riding)",
    "Zetland",
  ]

  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {
      try {
        setLocationValues(locvalues)
      } catch (error) {
        console.error("Error fetching location values: ", error)
      }
    }

    // Call the async function
    fetchData()
  }, [])

  return (
    <div className="page-content">
      {/* <Button
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => toggleModal()}
      >
        Add New Organisation
      </Button> */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {currentOrg.businessID
              ? "Organisation"
              : "Create Organisation"}
            &nbsp;
            {isValidUrl(currentOrg.companyLogo) && (
              <img
                src={currentOrg.companyLogo}
                alt={currentOrg.businessName}
                style={{ width: "30px", height: "auto" }}
              />
            )}
          </div>
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <div>Fields marked with an asterisk (*) are required.</div>
            <FormGroup>
              <Label for="businessID">
                Organisation ID<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="businessID"
                id="businessID"
                value={currentOrg.businessID || ""}
                onChange={handleInputChange}
                disabled={!currentOrg.businessID}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="businessName">
                Business Name<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="businessName"
                id="businessName"
                value={currentOrg.businessName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="alsoKnownAs">Also known as</Label>
              <Input
                type="text"
                name="alsoKnownAs"
                id="alsoKnownAs"
                value={currentOrg.alsoKnownAs}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="businessRegNumber">
                Business Registration Number
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="businessRegNumber"
                id="businessRegNumber"
                value={currentOrg.businessRegNumber}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="agencyPostCode">
                Agency Post Code<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="agencyPostCode"
                id="agencyPostCode"
                value={currentOrg.agencyPostCode}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="registeredOfficeAddress">
                Registered Office Address<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="registeredOfficeAddress"
                id="registeredOfficeAddress"
                value={currentOrg.registeredOfficeAddress}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="businessPhoneNumber">
                Business Phone Number<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="businessPhoneNumber"
                id="businessPhoneNumber"
                value={currentOrg.businessPhoneNumber}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="businessMobileNumber">
                Business Mobile Number<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="businessMobileNumber"
                id="businessMobileNumber"
                value={currentOrg.businessMobileNumber}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="companyLogo">Company Logo</Label>
              <Input
                type="file"
                name="companyLogo"
                id="companyLogo"
                onChange={handleFileChange}
              />
              {/* <Input
                      type="text"
                      name="companyLogo"
                      id="companyLogo"
                      value={currentOrg.companyLogo}
                      onChange={handleInputChange}
                    /> */}
            </FormGroup>

            <FormGroup>
              <Label for="areYouVATRegistered">
                Are you VAT Registered?<span style={{ color: "red" }}>*</span>
              </Label>
              <select
                name="areYouVATRegistered"
                id="areYouVATRegistered"
                value={currentOrg.areYouVATRegistered}
                onChange={handleInputChange}
                required
                className="form-control"
              >
                <option value="">Select VAT status</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </FormGroup>

            <FormGroup>
              <Label for="vatNumber">VAT Number</Label>
              <Input
                type="text"
                name="vatNumber"
                id="vatNumber"
                value={currentOrg.vatNumber}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="organisationalStatus">Status</Label>

              <select
                name="organisationalStatus"
                id="organisationalStatus"
                value={currentOrg.organisationalStatus}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select status</option>
                <option value="New">New</option>
                <option value="Progress">In Progress</option>
                <option value="Approved">Approved</option>
              </select>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" type="submit">
              {currentOrg.businessID ? "Update" : "Create"}
            </Button> */}
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={isLocationModalOpen} toggle={toggleLocationModal}>
        <ModalHeader toggle={toggleLocationModal}>
          {currentOrg.businessID ? "Location" : "Create Location"}
        </ModalHeader>
        <Form onSubmit={submitLocationHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="businessLocation">
                Business Location<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="businessLocation"
                id="businessLocation"
                value={currentOrg.businessLocation || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="city">
                City<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="city"
                id="city"
                value={currentOrg.city}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="businessEmailAddress">
                Business Email Address<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="email"
                name="businessEmailAddress"
                id="businessEmailAddress"
                value={currentOrg.businessEmailAddress}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" type="submit">
              {currentOrg.businessID ? "Update" : "Create"}
            </Button> */}
            <Button color="secondary" onClick={toggleLocationModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={isStaffModalOpen} toggle={toggleStaffModal}>
        <ModalHeader toggle={toggleStaffModal}>
          {currentOrg.businessID ? "Staff" : "Create Staff"}
        </ModalHeader>
        <Form onSubmit={submitStaffHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="staffLocation">Staff Location</Label>
              <Input
                type="text"
                name="staffLocation"
                id="staffLocation"
                value={currentOrg.staffLocation || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="noOfWorkers">Number of Workers</Label>
              <Input
                type="number"
                name="noOfWorkers"
                id="noOfWorkers"
                value={currentOrg.noOfWorkers}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="monthlyHoursAvailable">Monthly Hours Available</Label>
              <Input
                type="number"
                name="monthlyHoursAvailable"
                id="monthlyHoursAvailable"
                value={currentOrg.monthlyHoursAvailable}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="employeeType">Employee Type</Label>
              <select
                name="employeeType"
                id="employeeType"
                value={currentOrg.employeeType}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select employee type</option>
                <option value="PAYE">PAYE</option>
                <option value="PSC">PSC</option>
              </select>
            </FormGroup>
            <FormGroup>
              <Label for="workerType">Worker Type</Label>
              <select
                name="workerType"
                id="workerType"
                value={currentOrg.workerType}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select worker type</option>
                <option value="Chef">Chef</option>
                <option value="Catering Assistant">Catering Assistant</option>
                <option value="Registered Mental Health Nurse">
                  Registered Mental Health Nurse
                </option>
                <option value="Senior Care Assistant">
                  Senior Care Assistant
                </option>
                <option value="Health Care Assistant">
                  Health Care Assistant
                </option>
                <option value="Residential Support Worker">
                  Residential Support Worker
                </option>
                <option value="Senior Support Worker">
                  Senior Support Worker
                </option>
                <option value="Registered General Nurse">
                  Registered General Nurse
                </option>
                <option value="Support Worker">Support Worker</option>
                <option value="Care Worker">Care Worker</option>
              </select>
            </FormGroup>

            <FormGroup>
              <Label for="workerSkills">Worker Skills</Label>
              <Input
                type="text"
                name="workerSkills"
                id="workerSkills"
                value={currentOrg.workerSkills}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="workerType">Minimum Charge Hourly Rate(GBP)</Label>
              <Input
                type="number"
                name="minChargeHourlyRate"
                id="minChargeHourlyRate"
                value={currentOrg.minChargeHourlyRate}
                onChange={handleInputChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" type="submit">
              {currentOrg.businessID ? "Update" : "Create"}
            </Button> */}
            <Button color="secondary" onClick={toggleStaffModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={isBillingModalOpen} toggle={toggleBillingModal}>
        <ModalHeader toggle={toggleBillingModal}>
          {currentOrg.businessID ? "Billing" : "Create Billing"}
        </ModalHeader>
        <Form onSubmit={submitBillingHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="billAddress">Bill Address</Label>
              <Input
                type="text"
                name="billAddress"
                id="billAddress"
                value={currentOrg.billAddress || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="costCentre">Cost centre</Label>
              <Input
                type="text"
                name="costCentre"
                id="costCentre"
                value={currentOrg.costCentre}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="digitalBillingAddress">Digital Billing Address</Label>
              <Input
                type="email"
                name="digitalBillingAddress"
                id="digitalBillingAddress"
                value={currentOrg.digitalBillingAddress}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="digitalccBillingAddress">
                Digital Copy Billing Address
              </Label>
              <Input
                type="email"
                name="digitalccBillingAddress"
                id="digitalccBillingAddress"
                value={currentOrg.digitalccBillingAddress}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="billingCycle">Billing Cycle</Label>

              <select
                name="billingCycle"
                id="billingCycle"
                value={currentOrg.billingCycle}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select a cycle</option>
                <option value="Bi weekly">Bi weekly</option>
                <option value="Bi monthly">Bi monthly</option>
              </select>
            </FormGroup>

            <FormGroup>
              <Label for="creditTerm">Credit Term</Label>
              <Input
                type="text"
                name="creditTerm"
                id="creditTerm"
                value={currentOrg.creditTerm}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="bankName">Bank Name</Label>
              <Input
                type="text"
                name="bankName"
                id="bankName"
                value={currentOrg.bankName}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="accountNumber">Account Number</Label>
              <Input
                type="text"
                name="accountNumber"
                id="accountNumber"
                value={currentOrg.accountNumber}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="accountName">Account Name</Label>
              <Input
                type="text"
                name="accountName"
                id="accountName"
                value={currentOrg.accountName}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="sortCode">Sort Code</Label>
              <Input
                type="text"
                name="sortCode"
                id="sortCode"
                value={currentOrg.sortCode}
                onChange={handleInputChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" type="submit">
              {currentOrg.businessID ? "Update" : "Create"}
            </Button> */}
            <Button color="secondary" onClick={toggleBillingModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={isUsersModalOpen} toggle={toggleUsersModal}>
        <ModalHeader toggle={toggleUsersModal}>
          {currentOrg.businessID ? "Users" : "Create Users"}
        </ModalHeader>
        <Form onSubmit={submitUsersHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="userName">Name</Label>
              <Input
                type="text"
                name="userName"
                id="userName"
                value={currentOrg.userName}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="userEmail">Email</Label>
              <Input
                type="text"
                name="userEmail"
                id="userEmail"
                value={currentOrg.userEmail}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="userType">User Type</Label>
              <select
                name="userType"
                id="userType"
                value={currentOrg.userType}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select type</option>
                <option value="Agency User">Agency User</option>
                <option value="Agency Admin">Agency Admin</option>
              </select>
            </FormGroup>
            <FormGroup>
              <Label for="userCity">City/County</Label>
              <select
                name="userCity"
                id="userCity"
                value={currentOrg.userCity}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select City</option>
                {locationValues.map(city => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </FormGroup>
            <FormGroup>
              <Label for="userJobTitle">Job Title</Label>
              <Input
                type="text"
                name="userJobTitle"
                id="userJobTitle"
                value={currentOrg.userJobTitle}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="userPhoneNumber">Phone number</Label>
              <Input
                type="text"
                name="userPhoneNumber"
                id="userPhoneNumber"
                value={currentOrg.userPhoneNumber}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="userLocation">Location</Label>
              <Input
                type="text"
                name="userLocation"
                id="userLocation"
                value={currentOrg.userLocation}
                onChange={handleInputChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" type="submit">
              {currentOrg.businessID ? "Update" : "Create"}
            </Button> */}
            <Button color="secondary" onClick={toggleUsersModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={isAgreementModalOpen} toggle={toggleAgreementModal}>
        <ModalHeader toggle={toggleAgreementModal}>
          {currentOrg.businessID ? "Agreement" : "Create Agreement"}
        </ModalHeader>
        <Form onSubmit={submitAgreementHandler}>
          <ModalBody>
            <FormGroup>
              <div className="doc-container">
                <Label for="subContractingAgreement">
                  Sub Contracting Agreement
                </Label>
                {currentOrg.subContractingAgreement && (
                  <a
                    href={currentOrg.subContractingAgreement || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Sub Contracting Agreement
                  </a>
                )}
              </div>
              <Input
                type="file"
                name="subContractingAgreement"
                id="subContractingAgreement"
                onChange={handleFileChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" type="submit">
              {currentOrg.businessID ? "Update" : "Create"}
            </Button> */}
            <Button color="secondary" onClick={toggleAgreementModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={isWorkersModalOpen} toggle={toggleWorkersModal}>
        <ModalHeader toggle={toggleWorkersModal}>
          {currentOrg.businessID ? "Worker" : "Create Worker"}
        </ModalHeader>
        <Form onSubmit={submitWorkersHandler}>
          <ModalBody></ModalBody>
          <ModalFooter>
            {/* <Button color="primary" type="submit">
              {currentOrg.businessID ? "Update" : "Create"}
            </Button> */}
            <Button color="secondary" onClick={toggleWorkersModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={isSettingsModalOpen} toggle={toggleSettingsModal}>
        <ModalHeader toggle={toggleSettingsModal}>
          {currentOrg.businessID ? "Settings" : "Create settings"}
        </ModalHeader>
        <Form onSubmit={submitSettingsHandler}>
          <ModalBody></ModalBody>
          <ModalFooter>
            {/* <Button color="primary" type="submit">
              {currentOrg.businessID ? "Update" : "Create"}
            </Button> */}
            <Button color="secondary" onClick={toggleSettingsModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Table>
        <thead>
          <tr>
            <th>Business Name</th>
            <th>Business Reg Number</th>
            <th>Status</th>
            {/* <th>Logo</th> */}
            {/* Add additional headers for other fields */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* {organisation.map(org => ( */}
          <tr key={organisation.key}>
            <td>{organisation.businessName}</td>
            <td>{organisation.businessRegNumber}</td>
            <td>
              <span
                className={`badge badge-${
                  (organisation.organisationalStatus &&
                    organisation.organisationalStatus.toLowerCase()) ||
                  "new"
                }`}
              >
                {organisation.organisationalStatus || "New"}
              </span>
            </td>

            {/* <td>
               { isValidUrl(org.companyLogo) && (
                    <img src={org.companyLogo} alt={org.businessName}  style={{ width: '30px', height: 'auto' }} />
                )}

              </td> */}
            <td>
              <Dropdown
                className="custom-dropdown-menu"
                isOpen={dropdownOpenStates[organisation.key]}
                toggle={() => toggleDropdown(organisation.key)}
              >
                <DropdownToggle caret>View...</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => editOrganisation(organisation)}>
                    View Organisation
                  </DropdownItem>
                  {/* <DropdownItem
                    onClick={() => deleteOrganisation(organisation.key)}
                  >
                    Delete Organisation
                  </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem onClick={() => editLocation(organisation)}>
                    View Location
                  </DropdownItem>
                  <DropdownItem onClick={() => editStaff(organisation)}>
                    View Staff
                  </DropdownItem>
                  <DropdownItem onClick={() => editBilling(organisation)}>
                    View Billing
                  </DropdownItem>
                  <DropdownItem onClick={() => editUsers(organisation)}>
                    View Users
                  </DropdownItem>
                  <DropdownItem onClick={() => editAgreement(organisation)}>
                    View Agreement
                  </DropdownItem>
                  {/* <DropdownItem onClick={() => editWorkers(organisation)}>
                    View Workers
                  </DropdownItem>
                  <DropdownItem onClick={() => editSettings(organisation)}>
                    Edit Settings
                  </DropdownItem> */}
                </DropdownMenu>
              </Dropdown>
            </td>
          </tr>
          {/* ))} */}
        </tbody>
      </Table>
    </div>
  )
}

export default OrganisationClient
